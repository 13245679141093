import { Box, Button, Container, Grid, Typography } from "@mui/material";

export default function ImpressumSection(props) {

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box sx={{
                    marginBottom: theme => theme.spacing(10),
                    maxWidth: 750,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                }}>
                    <Typography 
                        sx={{
                            '& .has-text-align-center': {
                                textAlign: 'center',
                            },
                            '& a': {
                                color: theme => theme.palette.blue.main,
                            },
                            textAlign: 'center',
                            
                        }}
                        dangerouslySetInnerHTML={{__html: props.impressumData?.content?.rendered}}
                        
                        />    
                </Box>
            </Grid>
        </Grid>
    )
}