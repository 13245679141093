import { Box, Button, Collapse, Grid, Typography } from "@mui/material";
import { useState } from "react";


export default function AboutSection(props) {

    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    }

    const getButton = () => {
        return (<Typography
                    component="a"
                    sx={{
                    textDecoration: 'none',
                    color: theme => theme.palette.blue.main,
                    '&:hover': {
                        cursor: 'pointer',
                        textDecoration: 'underline',
                    }
                }}
                onClick={handleExpandClick}>{expanded ? "Weniger anzeigen" : "Weiterlesen"}
            </Typography>)
    }

    const getContent = () => {
        const content = props.data?.content?.rendered;
        return content && (expanded ? content : (content.slice(0,500) + "..."));
    }
    return (
        <Grid container sx={{marginBottom: theme => theme.spacing(6), maxWidth: {xs: '90%', md: 750}, marginLeft:'auto', marginRight:'auto'}}>
            <Grid item xs={12}
                sx={{
                    marginBottom: theme => theme.spacing(6),
                }}
            >
                <Typography variant="h4" sx={{marginBottom: theme => theme.spacing(8)}}>
                    Andrea Rosenberg
                </Typography>
                    <Box>
                        <Typography sx={{'& p:first-child': { display:'inline' }}} dangerouslySetInnerHTML={{__html: getContent()}} />
                        {getButton()}
                    </Box>
            </Grid>
        </Grid>
    )
}