import { AppBar, Box, Button, Container, IconButton, Link as MuiLink, Toolbar, Typography } from "@mui/material";
import { Link, animateScroll as scroll } from "react-scroll";
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from "react";

export default function Navigation(props) {


    const handleDrawerOpen = () => {
      console.log("attempt open")
      props.onDrawerOpen?.();
    }

    return (
        <AppBar
        component="nav"
        position='sticky'
        color="secondary"
        sx={{
          boxShadow:'none',
        }}
        >
        <Container
          maxWidth="lg"
        >
          <Toolbar
            sx={{
              // maxWidth: theme => theme.breakpoints.values.lg,
              // marginLeft: theme => `calc(50% - (${theme.breakpoints.values.lg}px / 2))`,
              '& a' : {
                color: theme => theme.palette.blue.main,
                textTransform: 'uppercase',
                marginRight: theme => theme.spacing(2),
                '&:hover': {
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  textDecorationThickness: '2px',
                  textUnderlineOffset: '4px',
                },
              },
              '& .activeNavLink': {
                textDecoration: 'underline',
                textDecorationThickness: '2px',
                textUnderlineOffset: '4px',
              }
            }}
          >
              <Typography
                variant="h6"
                component="div"
                sx={{
                  flexGrow: 1,
                  color: theme => theme.palette.blue.main,
                  fontWeight: 400,
                  textTransform: 'uppercase',
                }}
              >
                Andrea Rosenberg
              </Typography>
              <Box
                sx={{display: {
                  xs: 'none',
                  md: 'block',
                }
                }}
              >
                  {props.navItems?.map((item, index) => (
                    <Link spy hashSpy activeClass="activeNavLink" offset={-80} smooth to={item}>{item}</Link>
                  ))}
              </Box>   
              <IconButton
                color="blue"
                aria-label="open drawer"
                edge="end"
                onClick={handleDrawerOpen}
                sx={{ ...(props.drawerOpen && { display:'none'}), display: { xs: 'inline-flex', md: 'none'}}}
                >
                  <MenuIcon />
                </IconButton>
          </Toolbar>
        </Container>
      </AppBar>
    )
}