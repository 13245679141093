import { Grid, Typography } from "@mui/material";


export default function VitaSection(props) {

    const getItemList = (listName) => {
        return (
            props.data?.acf?.[listName].split('\r\n').map((item, index) => (
                <Typography sx={{marginBottom: theme => theme.spacing(1)}} key={item+"_"+index}>{item}</Typography>
            ))
        )
    }
    return (
        <Grid container rowSpacing={{xs:3, md:1}}
            sx={{
                maxWidth: {
                    xs: '90%',
                    md: 750,
                },
                marginLeft:'auto',
                marginRight: 'auto'
            }}
        >
            <Grid item xs={12} sx={{marginBottom: theme => theme.spacing(4)}}>
                <Typography variant="h4">
                    Vita
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography dangerouslySetInnerHTML={{__html: props.data?.content?.rendered}} />    
            </Grid>
            <Grid item xs={12} container>
                <Grid item xs={12} md={2}>
                    <Typography sx={{fontWeight: '800', color: theme =>theme.palette.blue.main}}>
                        Lehrer:
                    </Typography>
                    <br />
                    
                </Grid>
                <Grid item xs={12} md={10}>
                    {getItemList("teachers")}
                </Grid>
            </Grid>
            <Grid item xs={12} container>
                <Grid item xs={12} md={2}>
                    <Typography sx={{fontWeight: '800', color: theme =>theme.palette.blue.main}}>
                        Vorbilder:
                    </Typography>
                    <br />
                </Grid>
                <Grid item xs={12} md={10}>
                    {getItemList("idols")}
                </Grid>
            </Grid>
        </Grid>
    )
}