import axios from "axios";
import { useEffect, useState } from "react";
import { NUM_WORKS_PER_FETCH, WP_API_URL } from "../../constants";
import { Box, Button, CircularProgress, Fade, ImageList, ImageListItem, ImageListItemBar, Link, Typography } from "@mui/material";


const LoadingButton = (props) => {
    return (
        props.loading ?
        <Button color="blue" disabled component="a"><CircularProgress /></Button>
        :
        <Button color="blue" component="a" onClick={props.onClick}>Mehr laden</Button>
    )
}

export default function WorkSection(props) {

    const [works, setWorks] = useState([]);
    const [wpPageCount, setWpPageCount] = useState(0);
    const [worksIndex, setWorksIndex] = useState(1);
    const [isLoadingContent, setLoadingContent] = useState(false);
    useEffect(() => {
        Promise.all([
            axios.get(WP_API_URL + `works?order=asc&per_page=${NUM_WORKS_PER_FETCH}&page=1`),
        ]).then(([workResponse]) => {
            if(workResponse.data && workResponse.headers['x-wp-totalpages'] > 0) {
                console.log(workResponse.headers['x-wp-totalpages']);
                setWpPageCount(workResponse.headers['x-wp-totalpages'])
                setWorks(workResponse.data);
            }
        })
    }, [setWorks]);

    const loadAdditionalImages = () => {
        if(!isLoadingContent) {
            setLoadingContent(true);
            Promise.all([
                axios.get(WP_API_URL + `works?order=asc&per_page=${NUM_WORKS_PER_FETCH}&page=${(worksIndex+1)}`),
            ]).then(([workResponse]) => {
                if(workResponse.data) {
                    console.log(workResponse.data);
                    setWorks([...works, ...workResponse.data]);
                    setWorksIndex(worksIndex+1);
                    setLoadingContent(false);
                }
            })
        }
        
    }

    const getImageListItems = () => {
        return works && works.sort((a,b) => (parseInt(a.acf?.catalogueNumber) - parseInt(b.acf?.catalogueNumber))).map((item) => (
            <Fade in>
                <ImageListItem key={item.acf?.workImage}
                    sx={{
                        fontSize: '12px',
                    }}
                >
                    <img
                        src={`${item.acf?.workImage}?w=200x&fit=crop&auto=format`}
                        srcSet={`${item.acf?.workImage}?w=200&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.title?.rendered}
                        loading="lazy"
                    />
                    <ImageListItemBar
                        title={`${item.title.rendered}`}
                        subtitle={`${item.acf.technique}, ${item.acf.dimensions}, ${item.acf.location}`}
                        position="below"
                    />
                </ImageListItem>

            </Fade>
            
        ))
    }

    return (
        <Box>
            {/* IMAGE LIST DESKTOP */}
            <ImageList
                cols={1}
                gap={100}
                sx={{
                    // display: {
                    //     xs: 'none',
                    //     md: 'grid',
                    // }
                }}
            >
                {getImageListItems()}
            </ImageList>

            {(worksIndex < wpPageCount) && <Box sx={{
                display: 'flex',
                marginTop: theme => theme.spacing(8),
                minHeight: theme => theme.spacing(8),
                justifyContent: 'center', 
            }}> 
                <LoadingButton onClick={() => loadAdditionalImages()} loading={isLoadingContent} />
            </Box>}
            <Box sx={{
                textAlign:'center',
            }}>
                <Typography sx={{fontStyle: 'italic'}}>Drucke meiner Bilder können in verschiedenen Größen<br />über <Link href="http://www.fineartamerica.com/profiles/andrea-rosenberg">Fineartamerica</Link> erworben werden.</Typography>
            </Box>
        </Box>
    )
}