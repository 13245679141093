import { Grid, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";


export default function ExhibitionSection(props) {

    return (
        <Grid container
            sx={{
                marginBottom: theme => theme.spacing(12),
                maxWidth: {
                    xs: '90%',
                    md: 750,
                },
                marginLeft:'auto',
                marginRight:'auto'
            }}
        >
            <Grid item xs={12}
                sx={{
                    marginBottom: theme => theme.spacing(8),
                }}
            >
                <Typography variant="h4">
                    {props.title}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TableContainer>
                    <Table
                        sx={{
                            
                        }}
                        aria-labelledby={props.title}
                        size="small"
                    >
                        <TableBody
                        >
                            {props.data && props.data.split('\r\n').map(
                                (item,index) => {
                                    const [year, entry] = item.split(':');
                                    return (
                                        <TableRow
                                            key={year+"_"+index}
                                        >
                                            <TableCell padding="none" align="left"><Typography sx={{fontWeight: 800, color: theme => theme.palette.blue.main}}>{year}</Typography></TableCell>
                                            <TableCell><Typography>{entry}</Typography></TableCell>
                                        </TableRow>
                                    )
                                }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </Grid>
    )
}