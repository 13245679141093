import {createTheme } from '@mui/material/styles';

const primaryTypo = [
    "VCR OSD",
    "sans-serif",
].join(',');


let theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1920,
        }
    },
    palette: {
        primary: {
            main: '#212121',
        },
        secondary: {
            main: '#fff',
        },
        blue: {
            main: '#002D78',
        },
        lightBlue: {
            main: '#557A95',
        },
        grey: {
            main: '#a9a9a9',
        }
    },

})

theme = createTheme(theme, {
    typography: {
        h1: {
            fontSize: '4.9rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.9rem',
            },
            textTransform: 'uppercase',
        },
        h2: {
            textTransform: 'uppercase',
            color: theme.palette.blue.main,
            lineHeight: 2.5,
            marginBottom: theme.spacing(3),
            fontSize: '3.7rem',
            [theme.breakpoints.down('sm')]: {
                fontSize: '3rem',
            }

        },
        h3: {
            textTransform: 'uppercase',
            fontWeight: 400,
            fontSize: '3rem',
            lineHeight: '3.8rem',
            color: theme.palette.blue.main,
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
                lineHeight: '2.3rem',
            },
        },
        h4: {
            textTransform: 'uppercase',
            color: theme.palette.blue.main,
            fontWeight: 500,
            textAlign: 'center',
            fontSize: '2.1rem',
            textWrap: 'balance',
            [theme.breakpoints.down('sm')]: {
                fontSize: '1.5rem',
                wordBreak: 'break-all',
            }
        },
        h5: {
            textTransform: 'uppercase',
            fontWeight: 500,
            background: theme.palette.blue.main,
            color: theme.palette.secondary.main,
            display:'inline',
            padding: theme.spacing(0.3),
            lineHeight:2.4,
            fontStyle: 'italic',
        },
        h6: {
            color: theme.palette.blue.main,
            fontSize: '1rem',
            textTransform: 'uppercase',
            
        }
    },
    components: {
        MuiImageListItemBar: {
            styleOverrides: {
                title: {
                        fontSize: '20px',
                        color: theme.palette.blue.main,
                        lineHeight: 1.3,
                        textAlign: 'center',
                        textWrap: 'balance',
                        whiteSpace: 'normal',
                        marginTop: theme.spacing(1),
                        marginBottom: theme.spacing(1),
                },
                subtitle: {
                    fontSize: '16px',
                    textAlign: 'center',
                    fontStyle: 'italic',
                }
            }
        },
        MuiImageListItem: {
            styleOverrides: {
                img: {
                    maxHeight: '60vh',
                    objectFit: 'contain',
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '1rem',
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    color: theme.palette.blue.main,
                }
            }
        }
    }
})




export default theme;