import { Box, Grid, Typography } from "@mui/material";


export default function NewsSection(props) {

    const getLinesFromText = (t) => {
        return t.split('\r\n').map((item,index) => (
            <Typography dangerouslySetInnerHTML={{__html: item}} key={"item_"+index} />
        ))
    }
    const getNewsItems = () => {
        return props.data?.map((item,index) => {
            const d = new Date(item.date);
            return (
            <Grid
                item
                xs={12}
                container
                key={item.id} 
                sx={{
                    textAlign: 'center',
                    maxWidth: {
                        xs: '90%',
                        md: 750,
                    },
                    marginLeft:'auto',
                    marginRight:'auto'
                }}
            >
                <Grid item xs={12}>
                    <Typography sx={{color: theme => theme.palette.grey.main, fontSize: '.9rem', }}>{d.toLocaleDateString('de-DE')}</Typography>
                    {item.acf.isExhibition && <Typography variant="h5">Neue Ausstellung</Typography>}
                    <Typography variant="h4" sx={{marginBottom: theme => theme.spacing(2)}} dangerouslySetInnerHTML={{__html: item.title?.rendered}} />
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        maxWidth: '50%',
                    }}
                    component="img" src={item.acf?.coverImage} />
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{
                        fontStyle: 'italic',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        maxWidth: {
                            xs: '100%',
                            md: '60%',
                        }
                        }} 
                    dangerouslySetInnerHTML={{__html: item.content?.rendered}} />
                </Grid>
                {item.acf.isExhibition && 
                    <Grid item xs={12}>
                        {item.acf?.dateAndTime && 
                        <Box sx={{
                            marginBottom: theme => theme.spacing(3),
                        }}>
                            <Typography variant="h6">Zeitraum</Typography>
                            {getLinesFromText(item.acf.dateAndTime)}
                        </Box>}
                        {item.acf?.location &&
                        <Box sx={{
                            marginBottom: theme => theme.spacing(3),
                        }}>
                            <Typography variant="h6">Adresse</Typography>
                            {getLinesFromText(item.acf.location)}
                        </Box>}
                        {item.acf?.contactInfo &&
                        <Box sx={{
                            marginBottom: theme => theme.spacing(3),
                        }}>
                            <Typography variant="h6">Kontakt</Typography>
                            {getLinesFromText(item.acf.contactInfo)}
                        </Box>}
                    </Grid>
                }
            </Grid>)
        }
        )
    }
    return (
        <Grid container rowSpacing={12}>
            {getNewsItems()}
        </Grid>        
    )
}