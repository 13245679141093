import { Container, Typography } from "@mui/material";


export default function Section(props) {

    return (
        <Container
            component="section"
            maxWidth={props.fullWidth ? "none" : "lg"}
            disableGutters
            id={props.id}
            sx={{
                // borderBottom: theme => props.border && `1px ${theme.palette.lightBlue.main} solid`,
                paddingBottom: theme => theme.spacing(3),
                marginBottom: theme => theme.spacing(6),
                minHeight: props.fullHeight ? '100vh' : '30vh',
            }}
        >
            {props.title &&<Typography variant="h2" sx={{textAlign: 'center'}}>
                {props.title}
            </Typography>}
            {props.children}
        </Container>
    )
}