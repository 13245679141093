import logo from './logo.svg';
import './App.css';
import { AppBar, Box, Button, Container, CssBaseline, Divider, Drawer, Grid, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack, Toolbar, Typography } from '@mui/material';
import bgImg from './res/images/bg.jpg';
import Navigation from './components/Navigation';
import Section from './components/Section';
import AboutSection from './components/sections/AboutSection';
import ContactSection from './components/sections/ContactSection';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { WP_API_URL } from './constants';
import WorkSection from './components/sections/WorkSection';
import ExhibitionSection from './components/sections/ExhibitionSection';
import VitaSection from './components/sections/VitaSection';
import NewsSection from './components/sections/NewsSection';
import styled from '@emotion/styled';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Link } from 'react-scroll';
import ImpressumSection from './components/sections/ImpressumSection';

const drawerWidth = 200;
const navItems = ["home", "malerei", "news", "about", "kontakt", "impressum"];

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-start',
}));

const HomeSection = (props) => (
  <Box
            sx={{
              width:'100%',
              height:'100vh',
              position:'relative',
            }}
          >
            <Box
              sx={{
                width:'100%',
                height:'100vh',
                zIndex:10,
                position:'absolute',
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
              }}
            >
              <Typography
                color="secondary"
                variant="h1"
                sx={{
                  textAlign: 'center',
                  transform: theme => ({
                    xs: `translate(0, -${theme.spacing(12)})`,
                    md: `translate(0, -${theme.spacing(24)})`,
                  }),
                }}
              >
                  Atelier
                <br />
                Andrea Rosenberg
                </Typography>
            </Box>
            <Box
              sx={{
                width: '100%',
                height: '100vh',
                background: `url(${bgImg})`,
                backgroundPosition:'center center',
                backgroundSize: 'cover',
                position:'absolute',
                zIndex:0,
              }}
            />
    </Box>
)

function App() {

  const [bioContent, setBioContent] = useState([]);
  const [vitaContent, setVitaContent] = useState([]);
  const [contactContent, setContactContent] = useState([]);
  const [impressumContent, setImpressumContent] = useState([]);
  const [newsData, setNewsData] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  }

  useEffect(() => {
      Promise.all([
          axios.get(WP_API_URL + 'pages/13'),
          axios.get(WP_API_URL + 'pages/17'),
          axios.get(WP_API_URL + 'pages/19'),
          axios.get(WP_API_URL + 'news'),
          axios.get(WP_API_URL + 'pages/21'),
      ]).then(([bioResponse, vitaResponse, contactResponse, newsResponse, impressumResponse]) => {
        if(bioResponse.data) {
              setBioContent(bioResponse);
          }
        if(vitaResponse.data) {
            setVitaContent(vitaResponse);
        }
        if(contactResponse.data) {
            setContactContent(contactResponse);
        }
        if(newsResponse.data) {
            setNewsData(newsResponse);
        }
        if(impressumResponse.data) {
            setImpressumContent(impressumResponse);
        }
      })
  }, [setBioContent, setVitaContent, setContactContent, setNewsData, setImpressumContent]);

  return (
    <Box>
      <Container
        maxWidth="none"
        disableGutters
      >
        <CssBaseline />
        <Navigation onDrawerOpen={handleDrawerOpen} drawerOpen={drawerOpen} navItems={navItems} />
        <Stack>
          <Section id="home" fullWidth>
            <HomeSection />
          </Section>
          <Section border>
            <Typography variant="h3" sx={{textAlign: 'center'}}>
              Malerin in<br />
              Ammerland-Münsing<br />
              am Starnberger See<br />
            </Typography>
          </Section>
          <Section border id="malerei" title="Malerei">
            <WorkSection />
          </Section>
          <Section border id="news" title="News">
              <NewsSection data={newsData?.data} />
          </Section>
          <Section border id="about" title="About">
            <AboutSection data={bioContent?.data} />
            <ExhibitionSection data={vitaContent?.data?.acf?.exhibitions} title="Ausstellungen" />
            <ExhibitionSection data={vitaContent?.data?.acf?.groupExhibitions} title="Gruppenausstellungen" />
            <VitaSection data={vitaContent?.data} />
          </Section>
          <Section id="kontakt" title="Kontakt">
              <ContactSection contactData={contactContent?.data} impressumData={impressumContent?.data} />
          </Section>
          <Section id="impressum" title="Impressum">
              <ImpressumSection contactData={contactContent?.data} impressumData={impressumContent?.data} />
          </Section>
        </Stack>
      </Container>
      <Drawer
      sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
      }}
      // variant="persistent"
      anchor="right"
      open={drawerOpen}
    >
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          <ChevronRightIcon />
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List
        sx={{
          marginLeft: theme => theme.spacing(3),
          lineHeight: 2,
          fontSize: '1.3rem',
          '& a' : {
            color: theme => theme.palette.blue.main,
            textTransform: 'uppercase',
            marginRight: theme => theme.spacing(1),
            '&:hover': {
              cursor: 'pointer',
              textDecoration: 'underline',
              textDecorationThickness: '2px',
              textUnderlineOffset: '4px',
            },
          },
          '& .activeNavLink': {
            textDecoration: 'underline',
            textDecorationThickness: '2px',
            textUnderlineOffset: '4px',
          }
        }}
      >
          {navItems.map((text, index) => (
            <ListItem key={text} disablePadding>
                <Link onClick={handleDrawerClose} spy hashSpy activeClass="activeNavLink" offset={-80} smooth to={text}>{text}</Link>
            </ListItem>
          ))}
        </List>
    </Drawer>
    </Box>
  );
}

export default App;
