import { Box, Button, Container, Grid, Typography } from "@mui/material";

export default function ContactSection(props) {

    return (
        <Grid container>
            <Grid item xs={12}>
                <Box sx={{
                    marginBottom: theme => theme.spacing(10),
                }}>
                    <Typography 
                        sx={{
                            '& .has-text-align-center': {
                                textAlign: 'center',
                            },
                            '& a': {
                                color: theme => theme.palette.blue.main,
                            }
                            
                        }}
                        dangerouslySetInnerHTML={{__html: props.contactData?.content?.rendered}}
                        
                        />    
                </Box>
            </Grid>

        </Grid>
    )
}